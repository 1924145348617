<template>
  <div class="mt-5">
    <v-data-table
      :headers="headers"
      :items="userlists.users">
      <template #[`item.No`]="{ item }">
        {{ parseInt(item._id) + 1 }}
      </template>
      <template #[`item.createTime`]="{ item }">
        {{ item.createTime | formatDate }}
      </template>
      <template #[`item.department`]="{ item }">
        <v-chip
          small>
          {{ item.departmentName }}
        </v-chip>
      </template>
      <template #[`item.roles`]="{ item }">
        <v-chip
          small
          v-for="(role, index) in item.rolesDescription"
          :key="index">
          {{ role.name }}
        </v-chip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="getUserInfo(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="getUserChangePassword(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-lock-reset
              </v-icon>
            </v-btn>
          </template>
          <span>Change Password</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="deleteUser(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-delete-circle-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Delete User</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="openFormConfrimDelete"
      persistent
      max-width="320">
      <v-card>
        <v-card-title class="subheadline">Please, Confirm Delete User</v-card-title>
        <v-divider></v-divider>
        <v-card-text>Are you sure delete this user: <strong>{{deleteUserInfo.username}}</strong>?</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red darken-1"
            text
            @click="openFormConfrimDelete = false">NO</v-btn>
          <v-btn color="green darken-1"
            text
            @click.prevent="confirmDeleteUser">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openFormUser"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Update User</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="user.fullName"
              label="Fullname"
              outlined
              required />

            <v-text-field
              v-model="user.username"
              :rules="[rules.min]"
              label="Username"
              outlined
              required />

            <!-- <v-text-field
              v-model="user.password"
              type="password"
              label="Password"
              :rules="[rules.required, rules.min]"
              outlined />

            <v-text-field
              v-model="passwordConfirm"
              type="password"
              label="Confirm Password"
              :rules="[rules.required, rules.passwordConfirm, rules.min]"
              outlined /> -->

            <v-select
              v-model="user.department"
              :items="departments.departments"
              item-text="name"
              item-value="_id"
              label="User Department"
              outlined
              dense />

            <v-select
              v-model="user.roles"
              :items="roles.roles"
              item-text="name"
              item-value="_id"
              label="User Role"
              outlined
              dense />

            <v-checkbox
              v-model="user.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click="updateUserInfo">
            Update
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUser = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openFormChangePassword"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Change Password</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="userChangePassword.password"
              type="password"
              label="Password"
              :rules="[rules.required, rules.password]"
              outlined />

            <v-text-field
              v-model="userChangePassword.password_confirm"
              type="password"
              label="Confirm Password"
              :rules="[rules.required, rules.passwordConfirm, rules.password]"
              outlined />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click="updateUserChangePassword">
            Update
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormChangePassword = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserList',
  props: {
    userInfo: {
      type: Object
    },
  },

  async created() {
    await this.$store.dispatch('department/getAllDepartments');
    await this.$store.dispatch('userRole/getAllRoles');
  },

  computed: {
    ...mapGetters({
      departments: 'department/GET_DEPARTMENTS',
      roles: 'userRole/GET_ROLES'
    })
  },

  data() {
    return {
      headers: [
        // {
        //   text: 'No.',
        //   align: 'center',
        //   sortable: false,
        //   value: 'No'
        // },
        {
          text: 'Fullname',
          align: 'start',
          sortable: true,
          value: 'fullName'
        },
        {
          text: 'Username',
          align: 'start',
          sortable: true,
          value: 'username'
        },
        {
          text: 'Department',
          align: 'start',
          sortable: true,
          value: 'department'
        },
        {
          text: 'Roles',
          align: 'start',
          sortable: true,
          value: 'roles'
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime'
        },
        {
          align: 'start',
          sortable: true,
          value: 'action'
        },
      ],
      openFormUser: false,
      openFormChangePassword: false,
      openFormConfrimDelete: false,
      user: {
        _id: '',
        username: '',
        password: '',
        fullName: '',
        department: '',
        roles: '',
        activated: true,
      },
      userChangePassword: {
        _id: '',
        user_id: '',
        password: '',
        password_confirm: ''
      },
      deleteUserInfo: {
        _id: '',
        username: '',
      },
      passwordConfirm: '',
      rules: {
        required: (value) => !!value || 'Required, Please enter information.',
        passwordConfirm: () => (this.userChangePassword.password === this.userChangePassword.password_confirm) || 'Password must match',
        min: (v) => v.length >= 6 || 'Username minimum 6 characters.',
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            'Password minimum 8 characters with at least one capital letter, a number and a special character.'
          );
        }
      },
      userlists: [],
      role: {},
      department: {}
    };
  },

  watch: {
    userInfo: {
      immediate: true,
      handler(user) {
        this.userlists = user;
      }
    }
  },

  methods: {
    async getUserInfo(item) {
      this.editedIndex = this.userInfo.users.indexOf(item);

      this.user._id = item._id
      this.user.fullName = item.fullName;
      this.user.username = item.username;
      this.user.department = item.departments;
      this.user.roles = item.roles;

      this.openFormUser = true;
    },

    async getUserChangePassword(item) {
      this.editedIndex = this.userInfo.users.indexOf(item);
      this.userChangePassword._id = item._id;
      this.userChangePassword.user_id = item._id;
      // this.userChangePassword = { ...item };
      this.openFormChangePassword = true;
    },

    async updateUserInfo() {
      // this.user.department = this.user.department._id;
      // this.user.roles = this.user.roles._id;
      await this.$store.dispatch('user/updateUser', this.user);
      this.openFormUser = false;
    },

    async updateUserChangePassword() {
      await this.$store.dispatch('user/changePassword', this.userChangePassword);
      this.openFormChangePassword = false;
    },

    async deleteUser(item) {
      this.editedIndex = this.userInfo.users.indexOf(item);
      this.deleteUserInfo._id = item._id;
      this.deleteUserInfo.username = item.username;

      this.openFormConfrimDelete = true;
    },

    async confirmDeleteUser() {
      await this.$store.dispatch('user/deleteUser', this.deleteUserInfo);
      this.openFormConfrimDelete = false;
    }
  }
};
</script>

<style></style>
